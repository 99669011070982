<template>
    <component 
        :is="checkError" 
        storeName="geoviewer" />
</template>

<script>
import pageMeta from '@/mixins/pageMeta'
import { checkStore } from '@/utils/checkStore.js'
export default {
    mixins: [pageMeta],
    computed: {
        checkError() {
            if(checkStore('geoviewer')) {
                return () => import('@apps/GeoViewer')
            } else {
                return () => import('@/components/PageStoreError.vue')
            }
        }
        // widget() {
        //     try {
        //         return () => import('@apps/GeoViewer')
        //     } catch {
        //         return () => import('@/components/PageStoreError.vue')
        //     } 
        // }
    }
}
</script>
